/**
 * Download an image captured from an HTML canvas. Only intended for testing and
 * development.
 */
export function saveImage(canvas: HTMLCanvasElement, fileName: string) {
  const image = canvas.toDataURL('image/png', 1)

  const link = document.createElement('a')
  link.setAttribute('href', image.replace('image/png', 'image/octet-stream'))
  link.setAttribute('download', fileName)
  document.body.appendChild(link)

  link.click()
}
